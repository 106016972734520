<template>
  <div>
    <portal to="page-top-title">New Template</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/templates" :redirect="redirectRoute" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-select name="category_id" title="Category" :model="formData.category_id"
                               :options="data.categories" :disabled="!data.categories.length" required/>
          </column-input>
          <column-input>
            <form-group-select name="subject_categories" :model="formData.subject_categories"
                               :options="data.subject_categories" :disabled="!data.subject_categories.length" multiple required/>
          </column-input>
        </row>
        <row>
          <column-input>
            <form-group-select name="type" title="Type" :model="formData.type" :options="data.types"
                               :disabled="!data.types.length" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
        <component v-if="isText" :is="typeContent" :props="getPropsContent()" v-cloak/>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Loader from '@/views/components/simple/Loader'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import FormGroupTextarea from '@/views/components/form/FormGroupTextarea'
import TemplatesContentText from '@/views/pages/templates/partials/content/Text'
import Form from '@/modules/utils/Form'
import PageData from '@/views/pages/templates/partials/forms/PageData'

export default {
  name: 'TemplatesNew',
  metaInfo () {
    return { title: 'Templates | New' }
  },
  components: {
    BoxForm,
    Loader,
    FormGroupInput,
    FormGroupCheck,
    FormGroupSelect,
    FormGroupTextarea,
    TemplatesContentText
  },
  computed: {
    isText () { return this.formData.type.getValue() === this.idText },
    typeContent () { return this.typesContent[this.formData.type.getValue()] }
  },
  data () {
    return {
      idText: 1,
      formData: {
        name: Form.types.input(),
        category_id: Form.types.select(),
        subject_categories: Form.types.multiSelect(),
        type: Form.types.select(),
        active: Form.types.boolean(true),
        content: Form.types.input()
      },
      typesContent: {
        1: 'TemplatesContentText',
        10: 'TemplatesContentStripo'
      },
      data: {
        categories: [],
        shortcodes: [],
        subject_categories: [],
        types: []
      }
    }
  },
  created () {
    PageData.setResult(this.data)
    PageData.loadAll().then(() => {
      this.categoryCheckSelected()
    })
    PageData.loadTypes().then()
  },
  methods: {
    getPropsContent () {
      return {
        formData: this.formData,
        shortcodes: this.data.shortcodes
      }
    },
    redirectRoute (res) {
      return this.isText
        ? { name: 'templates-list' }
        : { name: 'templates-edit', params: { id: res.data.id } }
    },
    categoryCheckSelected () {
      const queryCatId = Number(this.$route.query.category)

      if (isNaN(queryCatId)) return

      const category = this.data.categories.find((category) => category.id === queryCatId)

      if (category) this.formData.category_id = Form.types.select(category)
    }
  }
}
</script>
